<template>
  <el-row class="changelog-item">
    <el-col
      :md="5"
      :lg="4"
    >
      <el-tag
        size="small"
        :type="type"
      >
        {{ change.type }}
      </el-tag>
      <p>
        {{ change.created_at | dateToString({ formatOverride: 'MMMM Do, YYYY' }) }}
      </p>
    </el-col>
    <el-col
      :md="17"
      :lg="18"
    >
      <h4>{{ change.title }}</h4>
      <slot />
    </el-col>
  </el-row>
</template>

<script>
import { dateToString } from '../../helpers';

export default {

  filters: {
    dateToString,
  },
  props: {
    change: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    type() {
      const types = {
        NEW: 'success',
        IMPROVEMENT: 'primary',
        FIX: 'danger',
        UPDATE: 'warning',
      };

      return types[this.change.type];
    },
  },

};
</script>

<style lang="scss" scoped>
  .changelog-item {
    padding-bottom: 1em;
    margin-bottom: 2em;
    border-bottom: 1px dotted #eee;
    h4 {
      margin: 4px 0 1.33em;
    }
    p {
      font-size: .8em;
      margin: .75em 0 .75em 4px;
    }
  }
</style>

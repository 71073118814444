<template>
  <div class="changelog-container">
    <h2>Latest Changes</h2>
    <changelog-item
      v-for="change of changes"
      :key="change.id"
      :change="change"
    >
      <div
        class="description"
        v-html="change.description"
      />
    </changelog-item>
  </div>
</template>

<script>
import ChangelogItem from '../../changelog/ChangelogItem.vue';
import { changes, user } from '@/adonis-api';

export default {
  components: {
    ChangelogItem,
  },
  data() {
    return {
      changes: [],
    };
  },

  mounted() {
    this.getChanges();
  },
  methods: {
    async getChanges() {
      this.changes = await changes.getChanges(true);
      if (this.changes.length) {
        // Update user's last seen changelog id value
        await user.setChangelog(this.$store.state.auth.myUserId, this.changes[0].id);
        this.$store.state.user.userDetails.changelog = this.changes[0].id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .changelog-container {
    max-width: 1280px;
  }
  .description {
    :deep(img) { max-width: 80%; display: block; margin: 0 auto; text-align: center;}
    :deep(h1), :deep(h2) { font-size: .85em; border-bottom: 1px solid #f7f7f7; padding-bottom: 4px; }
    :deep(h3), :deep(h4), :deep(h5) { font-size: .75em; border-bottom: 1px solid #f7f7f7; padding-bottom: 4px; }
  }
</style>

<template>
  <div>
    This page does not exist.
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
